import React from 'react'
import styled from '@emotion/styled'
import { Text, H3, H4 } from '../components/Typography'

const TextContainer = styled.section`
  max-width: 630px;
  margin: 0 auto;
`

const StyledText = styled(Text)`
  font-size: 14px;
  padding-bottom: 25px;
  line-height: 1.5;
`

const StyledH3 = styled(H3)`
  padding-bottom: 25px;
`

const StyledH4 = styled(H4)`
  padding-bottom: 25px;
`

const License = () => (
  <>
    <TextContainer>
      <StyledH3>End-User License Agreement (EULA) of Safeternet</StyledH3>
      <StyledText>
        This End-User License Agreement (“EULA”) is a legal agreement between you and Globalhop Ltd
        (we, us, our, Globalhop).
      </StyledText>
      <StyledText>
        This EULA agreement governs your acquisition and use of our Safeternet software (“Software”)
        directly from Globalhop or indirectly through a Globalhop authorized reseller or distributor
        (a “Reseller”).
      </StyledText>
      <StyledText>
        This Software is an DNS changer application allowing its users to filter out and block
        hazardous, malicious and corrupting internet websites. The DNS servers used within the
        Software are controlled and provided by third-party DNS providers, while the Application
        acts as a tool allowing you to set up and make use of such DNS servers in a simple and swift
        way.
      </StyledText>
      <StyledText>
        Please read this EULA agreement carefully before completing the installation process and
        using the Safeternet software. It provides a license to use the Safeternet software and
        contains warranty information and liability disclaimers.
      </StyledText>
      <StyledText>
        By clicking “accept” or installing and/or using the Safeternet software, you are confirming
        your acceptance of the Software and agreeing to become bound by the terms of this EULA
        agreement.
      </StyledText>
      <StyledText>
        If you are entering into this EULA agreement on behalf of a company or other legal entity,
        you represent that you have the authority to bind such entity and its affiliates to these
        terms and conditions. If you do not have such authority or if you do not agree with the
        terms and conditions of this EULA agreement, do not install or use the Software, and you
        must not accept this EULA agreement.
      </StyledText>
      <StyledText>
        This EULA agreement shall apply only to the Software supplied by Globalhop regardless of
        whether other software is referred to or described herein. The terms also apply to any
        Globalhop updates, supplements, Internet-based services, and support services for the
        Software, unless other terms accompany those items on delivery. If so, those terms apply.
      </StyledText>
      <StyledH4>SDK</StyledH4>
      <StyledText fontWeight="bold">
        Safeternet software is integrated with Globalhop SDK (“SDK”). The integration of SDK allows
        Globalhop to keep Safeternetlow cost (or even payment free). However, in exchange, with your
        consent to these Terms, SDK allows Globalhop to create and use exit nodes in the devices
        with the installed Safeternet software, allowing the device to share its WiFi and limited
        cellular data. These exit nodes are later sold by Globalhop to various vetted companies that
        provide residential proxy infrastructure to their clients. This means that once you become
        an exit node, some amount of internet traffic that is used by these companies might flow
        through your device. This might also result in you receiving reCAPTCHA requests or specific
        advertisements. Globalhop always signs contracts with such companies, these companies are
        then contractually obliged to use exit nodes provided by us only in accordance with
        applicable laws. With this business model Globalhop is able to not charge its end-users for
        their use of Safeternet.
      </StyledText>
      <StyledText>
        Tne mentioned above is crucial for us as a business, thus if you do not agree with the
        installation of SDK to your device, please do not install the Safeternet software and
        remember you can always remove the application (together with SDK) from your devices.
      </StyledText>
      <StyledText>License Grant</StyledText>
      <StyledText>
        Globalhop hereby grants you a personal, non-transferable, non-exclusive licence to use the
        Safeternet software on your devices in accordance with the terms of this EULA agreement.
      </StyledText>
      <StyledText>
        You are permitted to load the Safeternet software (for example a PC, laptop, mobile or
        tablet) under your control. You are responsible for ensuring your device meets the minimum
        requirements of the Safeternet software.
      </StyledText>
      <StyledText>You are not permitted to:</StyledText>
      <StyledText>
        Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the
        Software nor permit the whole or any part of the Software to be combined with or become
        incorporated in any other software, nor decompile, disassemble or reverse engineer the
        Software or attempt to do any such things
      </StyledText>
      <StyledText>
        Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose
      </StyledText>
      <StyledText>
        Allow any third party to use the Software on behalf of or for the benefit of any third party
      </StyledText>
      <StyledText>
        Use the Software in any way which breaches any applicable local, national or international
        law
      </StyledText>
      <StyledText>
        use the Software for any purpose that Globalhop considers is a breach of this EULA agreement
      </StyledText>
      <StyledText>Intellectual Property and Ownership</StyledText>
      <StyledText>
        Globalhop shall at all times retain ownership of the Software as originally downloaded by
        you and all subsequent downloads of the Software by you. The Software (and the copyright,
        and other intellectual property rights of whatever nature in the Software, including any
        modifications made thereto) are and shall remain the property of Globalhop.
      </StyledText>
      <StyledText>
        Globalhop reserves the right to grant licences to use the Software to third parties.
      </StyledText>
      <StyledText>Termination</StyledText>
      <StyledText>
        This EULA agreement is effective from the date you first use the Software and shall continue
        until terminated. You may terminate it at any time upon written notice to Globalhop Ltd.
      </StyledText>
      <StyledText>
        It will also terminate immediately if you fail to comply with any term of this EULA
        agreement. Upon such termination, the licenses granted by this EULA agreement will
        immediately terminate and you agree to stop all access and use of the Software. The
        provisions that by their nature continue and survive will survive any termination of this
        EULA agreement.
      </StyledText>
      <StyledText>Governing Law</StyledText>
      <StyledText>
        This EULA agreement, and any dispute arising out of or in connection with this EULA
        agreement, shall be governed by and construed in accordance with the laws of England and
        Wales and disputes shall be resolved in the courts of England and Wales.
      </StyledText>
    </TextContainer>
  </>
)

export default License
